<!-- 短信验证弹窗 -->
<template>
    <Dialog :visible.sync="dialogVisible" class="isPhone-Dialog" width="600px"   :title='$t("userPage.text322")'>
        <!-- 手机号 -->
        <Form
            :model="dialogForm"
            :rules="rules"
            ref="ruleForm"
            label-width="150px"
        >
            <Form-item :label='$t("userPage.text89")+"："' prop="phone">
                <Input v-model="userData.phone" v-if="userData.phone" disabled></Input>
                <Input v-model="dialogForm.phone" v-if="!userData.phone" :placeholder='$t("userPage.text90")'></Input>
            </Form-item>
            <!-- 验证码 -->
            <Form-item :label='$t("userPage.text91")+"："' prop="code">
                <Input v-model="dialogForm.code" class="Input-code" :placeholder='$t("userPage.text92")'></Input>
                <Button class="code-btn" :disabled="!!(dialogForm.timeout)" @click="getCode">{{ dialogForm.timeout ? dialogForm.timeout + 's' : $t("userPage.text93") }}</Button>
            </Form-item>
        </Form>
        <div class="isPhone-item">
            <span></span>
        </div>
        <!-- 提交 -->
        <div class="btn-center">
            <Button type="primary" class="isPhone-btn" @click="submitPhone('ruleForm')">{{$t("userPage.text94")}}</Button>
        </div>
    </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
// import { _phoneIsValid } from '../../../core/utils/utils'
import { _smsCode } from '../../../core/api/modules/user'
import { _updatePhoneSmsCodeAction } from '../../../core/comp/pages/finance'
import { Message, Dialog, Input, Button, Form, FormItem } from 'element-ui'
export default {
    components: {
        Dialog,
        Input,
        Button,
        Form,
        FormItem
    },
    computed: {
        ...mapGetters([
            'userData'
        ])
    },
    data () {
        //手机号校验
        const Phone = (rule, value, callback) => {
            if(!this.userData.phone) {
                if (!value) {
                    return callback(new Error(this.$t("userPage.text95")));
                } else {
                    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error(this.$t("userPage.text96")));
                    }
                }
            } else {
                callback();
            }          
        };
        return {
            dialogVisible: false, // 验证手机弹窗开关
            dialogForm: { // 绑定手机相关表单
                phone: '', // 手机号
                code: '', // 验证码
                timeout: 0, // 倒计时
            },
            interval: 0, // 验证码倒计时
            rules:{
                phone: [{ required: true, validator: Phone, trigger: "blur" },],
                code: [{ required: true, message: this.$t("userPage.text97"), trigger: "blur" },],
            }
        }
    },
    methods: {
        // 打开
        open () {
            this.dialogVisible = true
        },
        // 获取验证码
        getCode () {
            if (this.dialogForm.timeout) return
            const phone = (this.dialogForm.phone || this.userData.phone)
            _smsCode(phone, 3).then(res => {
                if (res) {
                    this.dialogForm.timeout = 60
                    this.interval = setInterval(() => {
                        this.dialogForm.timeout --
                        if (this.dialogForm.timeout == 0) clearInterval(this.interval)
                    }, 1000)
                }
            })
        },
        // 验证手机号
        submitPhone () {
            if (!this.dialogForm.code) return Message.error(this.$t("userPage.text27"))
            const params = {
                phone: this.dialogForm.phone || this.userData.phone,
                smsCode: this.dialogForm.code
            }
            _updatePhoneSmsCodeAction(params).then(res => {
                if (res) {
                    this.dialogVisible = false
                    setTimeout(() => {
                        this.$emit('pass')
                    }, 1000)
                }
            })
        }
    },
    beforeDestroy () {
        if (this.interval) clearInterval(this.interval)
    }
}
</script>
<style lang="scss">
// $imgUrl: '~@/assets/images/my/';
.isPhone-Dialog{
    width: 100%;
    height: 100%;
    .el-dialog{
        margin: 0 !important;
        position:absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform:translate(-50%,-50%);
        box-shadow: 0px 14px 21px rgb(0 0 0 / 57%);
        .el-dialog__body{
            .el-form-item{
                .el-form-item__content{
                    display: flex;
                    .Input-code{
                        width: 60%;
                        margin-right: 15px;
                    }
                    .code-btn{
                        color: #fff;
                        padding: 12px 10px;
                        background:#00a6ff;
                    }
                }
            }
        }
        .el-dialog__header{
            background: #00a6ff;
            span{
                color: #fff !important;    
            }
            .el-icon-close{
                color: #fff;    
            }
        }
    }
    // .isPhone-btn{
    //     border:none;
    //     width: 130px;
    //     height: 40px;
    //     font-size: 16px;
    //     box-shadow: 0 1px 0px 0 #f5e68e;
    //     background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/100%;
    // }
    .btn-center {
        margin-top: 40px;
        text-align: center;
        .isPhone-btn{
            width:100px;
            padding:12px 20px;
        }
    }
}
</style>