<!-- 顶部导航 -->
<template>
    <div class="my-tab">
        <div class="tab-item" v-for="(item, i) in arr" :key="i" @click="tab(i)" :class="index==i?'active-tab':''">{{ item }}</div>
    </div>
</template>

<script>
export default {
    props: {
        tabIndex: { // 外部注入的tab下标
            type: Number,
            default: 0,
        },
        arr: { // tab列表数组
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            index: 0
        }
    },
    created () {
        this.index = this.tabIndex
    },
    watch: {
        tabIndex (val) {
            this.index = val
        },
        '$route.query.t': {
            handler () {
                setTimeout(() => {
                    const i = this.$route.query.tab
                    if (!isNaN(i))  this.tab(this.$route.query.tab)
                })
            },
            immediate: true
        }
    },
    methods: {
        tab (index) {
            this.index = index
            this.$emit('tab', index)
        }
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.my-tab {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tab-item {
        min-width: 160px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        position: relative;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
        border: 1px solid #ce06cd;
        border-left: none;
        border-radius: 7px 7px 0 0;
        color: #ce06cd;
        background: none;
    }
    .tab-item:nth-child(1){
        border-left: 1px solid #ce06cd;
    }
    .active-tab {
        background: #ce06cd;
        border: 1px solid #ce06cd;
        color: #fff;
    }
}
</style>