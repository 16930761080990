<!-- 彩金管理 -->
<template>
    <div class="viewsPages">
        <div class="views-Jackpot">
            <!-- 顶部Tab -->
            <Tab :arr="tabArr" @tab="tab" />
            <!-- 列表 -->
            <div class="jackpot-list" >
                <div class="jackpot-wrap" v-infinite-scroll="load" :infinite-scroll-immediate="false">
                    <!-- 彩金 -->
                    <div class="jackpot-item" v-for="(item, i) in jackpotList" :key="i">
                        <!-- 名字 -->
                        <div class="jackpot-title">{{ item.name }}</div>
                        <!-- 金额 -->
                        <div class="jackpot-right"><span>￥</span>{{ item._amount }}</div>
                        <div class="jackpot-flex">
                            <div class="jackpot-grod">
                                <div>{{$t("userPage.text147")+ ":"}}</div>
                                <!-- 领取有效期 -->
                                <div v-if="item.Validuntil">{{ _formatDate(item.Validuntil) }}</div>
                                <!-- 结束时间 -->
                                <div>{{ _formatDate(item.overdueTime) }}</div>
                            </div>
                            <!-- 状态 -->
                            <div class="jackpot-btn" :class="'jackpot-btn' + item.state" @click="handleItem(item)">{{ item._stateName }}</div>
                        </div>
                    </div>
                </div>
                <div class="jackpot-null" v-if="jackpotList.length">{{ more ? $t("userPage.text148") : $t("userPage.text149") }}</div>
                <Empty v-if="!jackpotList.length" :description='$t("userPage.text150")' :image="require('@/assets/images/my/null.png')"></Empty>
            </div>
            <!-- 彩金领取弹窗 -->
            <Dialog
                :title='$t("userPage.text151")'
                :visible.sync="dialogVisible"
                :show-close='false'
                center
                width="400px">
                    <div class="Dialog-content">
                        <div class="amount">￥{{ targetItem._amount }}</div>
                        <div>{{$t("userPage.text152")}}{{ targetItem._multiple }}{{$t("userPage.text153")}}</div>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <Button @click="dialogVisible = false">{{$t("userPage.text154")}}</Button>
                        <Button type="primary" @click="receive">{{$t("userPage.text155")}}</Button>
                    </div>
            </Dialog>
            <VerificationCode ref="veriCode" @pass="receive"></VerificationCode>
        </div>
    </div>
</template>

<script>
import vue from 'vue'
import { InfiniteScroll, Empty, Dialog, Button } from 'element-ui'
vue.use(InfiniteScroll)
import { mapGetters,mapActions } from 'vuex'
import Tab from '../components/Tab.vue'
import { _formatDate } from '@/core/utils/utils'
import VerificationCode from './VerificationCode.vue'
import { _jackpotList, _receiveJackpot } from '@/core/api/modules/promo'
export default {
    components: {
        Tab,
        Empty,
        Dialog,
        Button,
        VerificationCode
    },
    data () {
        return {
            activeIndex: 0,
            tabArr: [this.$t("userPage.text156"), this.$t("userPage.text157"), this.$t("userPage.text158"), this.$t("userPage.text159")],
            type: 3, // 3-全部  0-可领取  1-已领取  2-已过期
            page: 0,
            dialogVisible: false, // 领取弹窗开关
            targetItem: {}, // 领取目标
        }
    },
    computed: {
        more () { // 是否还有更多数据
            return !this.jackpotList.length || this.jackpotList.length < this.jackpotTotals
        },
        ...mapGetters([
            'userData',
            'jackpotList',
            'jackpotTotals'
        ])
    },
    methods: {
        _formatDate,
        
        ...mapActions([
            'jackpotListAction', // 更新彩金列表数据
        ]),
        // 切换导航
        tab (num) {
            if (this.activeIndex == num) return
            this.activeIndex = num
            switch (num) {
                case 0:
                    this.type = 3
                    break
                case 1:
                    this.type = 0
                    break
                case 2:
                    this.type = 1
                    break
                case 3:
                    this.type = 2
                    break
            }
            
            this.page = 0
            this.jackpotListAction([])
            this.$nextTick(() => {
                this.load()
            })
        },
        // 加载更多
        load () {
            if (!this.more) return
            this.page++
            _jackpotList({
                pageSize: 10,
                currentPage: this.page,
                state: this.type
            }).then(res => {
                console.log('----彩金', res)
                if (res) {
                    this.page++
                }
            })
        },
        // 点击某条彩金
        handleItem (item) {
            switch (item.state) {
                case 0: // 未领取-打开领取弹窗
                    this.targetItem = item
                    this.dialogVisible = true
                    break
                case 1: // 已领取
                    //Message.warning('彩金已领取过了')
                    break
                case 2: // 已过期
                    //Message.warning('已过期，无法领取')
                    break
            }
        },
        // 领取
        receive () {
            if(this.userData.phoneValid != 1){
                // 验证手机
                this.$refs.veriCode.open()
            }else{
                _receiveJackpot(this.targetItem.id).then(res => {
                    if (res) {
                        this.dialogVisible = false
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';

// 彩金
.views-Jackpot{
    width: 1110px;
    margin: 0 auto;
    padding: 20px 0px;
    .jackpot-list {
        margin: 20px 0;
        overflow-y: auto;
        height: 600px;
        border-radius: 10px;
        background:#DEDEDE;
        .jackpot-wrap{
            display:grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 20px 10px;
            padding: 20px 10px;
            .jackpot-item {
                padding: 20px;
                position: relative;
                border-radius: 10px;
                background: #fff;
                box-shadow: 0px 2px 5px 0 #ce06cd;
                .jackpot-title{
                    font-size: 24px;
                    font-weight: 400;
                }
                .jackpot-flex{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .jackpot-grod{
                        color: #8c8b8b;
                        line-height: 20px;
                    }
                    .jackpot-btn {
                        width: 100px;
                        color: #fff;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 6px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        background: #ccc;
                        // background: url(#{$imgUrl}jackpot_btn1.png) no-repeat center/contain;
                    }
                    .jackpot-btn0 {
                        cursor:pointer;
                        background: #ce06cd;
                        // background: url(#{$imgUrl}jackpot_btn.png) no-repeat center/contain;
                    }
                }
                .jackpot-right{
                    text-align: right;
                    margin: 15px 0;
                    margin-right: 40px;
                    font-size: 30px;
                    font-weight: 700;
                    color: #ce06cd;
                    span{
                        font-size:22px;
                    }
                }
            }
        }
        .jackpot-null{
            width: 100%;
            color: #a0a0a0;
            margin: 10px auto;
            line-height: 30px;
            text-align:center;
        }
    }
}
</style>
<style lang="scss">
// 彩金
.views-Jackpot{
    .jackpot-list .el-empty .el-empty__description p{
        color: #a0a0a0;
        font-size: 16px;
        font-weight: 700;
    }
}
</style>